@font-face {
  font-family: "Noto Sans KR";
  src: local("Noto Sans KR"),
    url("shared/font/Noto_Sans_KR/NotoSansKR-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Noto Sans KR";
  src: local("Noto Sans KR"),
    url("shared/font/roboto-v30-latin-regular.woff2") format("woff2");
  font-display: swap;
  unicode-range: U+0041-005A, U+0061-007A;
}

@font-face {
  font-family: "pass";
  font-style: normal;
  font-weight: 400;
  src: url(data:application/font-woff;charset=utf-8;base64,d09GRgABAAAAAATsAA8AAAAAB2QAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABGRlRNAAABWAAAABwAAAAcg9+z70dERUYAAAF0AAAAHAAAAB4AJwANT1MvMgAAAZAAAAA/AAAAYH7AkBhjbWFwAAAB0AAAAFkAAAFqZowMx2N2dCAAAAIsAAAABAAAAAQAIgKIZ2FzcAAAAjAAAAAIAAAACAAAABBnbHlmAAACOAAAALkAAAE0MwNYJ2hlYWQAAAL0AAAAMAAAADYPA2KgaGhlYQAAAyQAAAAeAAAAJAU+ATJobXR4AAADRAAAABwAAAAcCPoA6mxvY2EAAANgAAAAEAAAABAA5gFMbWF4cAAAA3AAAAAaAAAAIAAKAE9uYW1lAAADjAAAARYAAAIgB4hZ03Bvc3QAAASkAAAAPgAAAE5Ojr8ld2ViZgAABOQAAAAGAAAABuK7WtIAAAABAAAAANXulPUAAAAA1viLwQAAAADW+JM4eNpjYGRgYOABYjEgZmJgBEI2IGYB8xgAA+AANXjaY2BifMg4gYGVgYVBAwOeYEAFjMgcp8yiFAYHBl7VP8wx/94wpDDHMIoo2DP8B8kx2TLHACkFBkYA8/IL3QB42mNgYGBmgGAZBkYGEEgB8hjBfBYGDyDNx8DBwMTABmTxMigoKKmeV/3z/z9YJTKf8f/X/4/vP7pldosLag4SYATqhgkyMgEJJnQFECcMOGChndEAfOwRuAAAAAAiAogAAQAB//8AD3jaY2BiUGJgYDRiWsXAzMDOoLeRkUHfZhM7C8Nbo41srHdsNjEzAZkMG5lBwqwg4U3sbIx/bDYxgsSNBRUF1Y0FlZUYBd6dOcO06m+YElMa0DiGJIZUxjuM9xjkGRhU2djZlJXU1UDQ1MTcDASNjcTFQFBUBGjYEkkVMJCU4gcCKRTeHCk+fn4+KSllsJiUJEhMUgrMUQbZk8bgz/iA8SRR9qzAY087FjEYD2QPDDAzMFgyAwC39TCRAAAAeNpjYGRgYADid/fqneL5bb4yyLMwgMC1H90HIfRkCxDN+IBpFZDiYGAC8QBbSwuceNpjYGRgYI7594aBgcmOAQgYHzAwMqACdgBbWQN0AAABdgAiAAAAAAAAAAABFAAAAj4AYgI+AGYB9AAAAAAAKgAqACoAKgBeAJIAmnjaY2BkYGBgZ1BgYGIAAUYGBNADEQAFQQBaAAB42o2PwUrDQBCGvzVV9GAQDx485exBY1CU3PQgVgIFI9prlVqDwcZNC/oSPoKP4HNUfQLfxYN/NytCe5GwO9/88+/MBAh5I8C0VoAtnYYNa8oaXpAn9RxIP/XcIqLreZENnjwvyfPieVVdXj2H7DHxPJH/2/M7sVn3/MGyOfb8SWjOGv4K2DRdctpkmtqhos+D6ISh4kiUUXDj1Fr3Bc/Oc0vPqec6A8aUyu1cdTaPZvyXyqz6Fm5axC7bxHOv/r/dnbSRXCk7+mpVrOqVtFqdp3NKxaHUgeod9cm40rtrzfrt2OyQa8fppCO9tk7d1x0rpiQcuDuRkjjtkHt16ctbuf/radZY52/PnEcphXpZOcofiEZNcQAAeNpjYGIAg///GBgZsAF2BgZGJkZmBmaGdkYWRla29JzKggxD9tK8TAMDAxc2D0MLU2NjENfI1M0ZACUXCrsAAAABWtLiugAA) format("woff");
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Nanum+Gothic:wght@400;700;800&display=swap");
/* http://meyerweb.com/eric/tools/css/reset/ 
  v2.0 | 20110126
  License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  -ms-overflow-style: none;
  padding: 0;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  line-height: 100%;
}

body::-webkit-scrollbar {
  display: none;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 820px;
  background: #f7f8fa;
}

.outer {
  background-color: #f7f8fa;
  padding: 64px 140px 64px 0;
  box-sizing: border-box;
  min-width: 1920px;
  min-height: 820px;
  position: relative;
}

.outer.create {
  min-width: 100%;
  padding: 0;
  background: #fff;
}

/* navbar */
.sidebar {
  min-height: 820px;
  height: 100%;
  /* height: calc(100% - 100px); */

  width: 200px;
  /* border-right: solid 1px lightgray; */
  /* display: flex; */
  /* float: left; */
  /* position: absolute;
  top: 0;
  left: 0; */
  border-radius: 0px 20px 20px 0px;
  color: #ffffff;
  background-color: #1d232b;
  position: absolute;
  top: 0px;
  z-index: 99;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;

  /* identical to box height, or 14px */

  letter-spacing: -0.01em;

  /* Font/White */

  color: #ffffff;
}

.topbar {
  width: 100%;
  height: 100px;
  border-bottom: solid 1px lightgray;
  white-space: nowrap;
}

.sidebarList {
  height: auto;
  /* padding: 5% 0; */
  border-radius: 0px 20px 20px 0px;
}

.sidebarList .row {
  /* margin-bottom: 24px; */
  /* height: 25px; */
  /* display: flex;
  flex-direction: row;
  align-items: center; */
  width: 200px;
  /* height: 36px; */
}

.sidebar .row div {
  padding: 14px 24px 12px;
}

.sidebar .row:hover {
  cursor: pointer;
}

.sidebarList #active {
  background-color: #f7f8fa;
  font-weight: 500;
  color: #1d232b;
}

.container {
  position: relative;
  height: 100vh;
  /* width: calc(100% - 305 px);
  height: 95vh; */
  /* position: absolute;
  width: 1440px;
  height: 900px;
  left: calc(50% - 1440px / 2 + 100px);
  top: calc(50% - 900px / 2 + 0.5px); */

  background: #ffffff;
  /* CG/9 */

  box-shadow: 10px 20px 35px rgba(148, 163, 184, 0.2);
  border-radius: 20px;
  margin-left: 340px;
  padding: 64px 98px;
  height: 100%;
  box-sizing: border-box;
  min-height: 952px;
}

.title {
  /* position: absolute; */
  /* width: 124px;
  height: 32px;
  left: calc(50% - 124px / 2 - 460px); */
  /* left: calc(50% - 230px / 2 - 460px); */
  /* left: 98px; */
  /* top: calc(50% - 32px / 2 - 379.5px); */
  /* width: 90%; */

  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  /* identical to box height, or 32px */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* N/9 */
  color: #313b49;
}

.subTitle {
  position: absolute;
  left: 98px;
  top: 112px;
  /* left: calc(50% - 230px / 2 - 460px); */
  /* top: calc(50% + 16px - 379.5px); */

  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 32px */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* N/9 */
  color: #313b49;
}

.modalTitle {
  /* position: absolute;
  left: 67px;
  top: 8%; */
  /* top: calc(50% - 32px / 2 - 379.5px); */
  width: calc(100% - 67px);

  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  /* identical to box height, or 32px */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* N/9 */
  color: #313b49;
}

.modalSubTitle {
  /* position: absolute;
  left: 67px; */
  /* left: calc(50% - 230px / 2 - 460px); */
  /* top: 13.5%; */

  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 32px */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  margin-top: 16px;

  /* N/9 */
  color: #313b49;
}

.modalCloseBtn {
  position: absolute;
  right: 67px;
  cursor: pointer;
}

.content {
  /* position: absolute; */
  /* left: 98px;
  top: 15%; */
  /* width: 80%; */
  /* width: calc(100% - 196px); */
  height: 75%;
  overflow-y: auto;
  overflow-x: hidden;
}

.content::-webkit-scrollbar,
.tabContent::-webkit-scrollbar {
  display: none;
}

.closeBtn {
  /* Vector */
  position: absolute;
  right: 3%;
  cursor: pointer;
  /* right: 25%;
  left: 25%;
  top: 25%;
  bottom: 25%; */
}

.tab {
  /* position: absolute;
  top: 15%;
  left: 98px; */
}

.tabContent {
  width: calc(100% - 196px);
  left: 98px;
  top: 20%;
  height: 70%;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
}

/* 버튼 관리 input range */

/* input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fbfcfd;
  cursor: pointer;
  border: 1px solid #313b49;
  box-shadow: -407px 0 0 400px #313b49;
}

input[type="range"] {
  -webkit-appearance: none;
  background: #edeef3;
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  height: 10px;
  box-shadow: inset 0 0 5px #edeef3;
} */

/*Range Reset*/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 20rem;
}

/* Removes default focus */
input[type="range"]:focus {
  outline: none;
  box-shadow: none;
}

/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider track */
input[type="range"]::-webkit-slider-runnable-track {
  background-color: #edeef3;
  border-radius: 0.5rem;
  height: 0.5rem;
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  margin-top: -4px;

  /*custom styles*/
  background-color: #edeef3;
  height: 1rem;
  width: 1rem;
  border: 2px solid #313b49;
  border-radius: 15px;
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.1);
}

/*generated with Input range slider CSS style generator (version 20211225)
https://toughengineer.github.io/demo/slider-styler*/
input[type="range"].slider {
  height: 2.2em;
  -webkit-appearance: none;
}

/*progress support*/
input[type="range"].slider.slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 16px + var(--ratio) * (100% - 16px));
}

input[type="range"].slider:focus {
  outline: none;
}

/*webkit*/
input[type="range"].slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 1em;
  background: #fbfcfd;
  border: 2px solid #313b49;
  box-shadow: 0 0 2px black;
  margin-top: calc(8px * 0.5 - max(16px * 0.5, 2px));
}

input[type="range"].slider::-webkit-slider-runnable-track {
  height: 8px;
  border: none;
  border-radius: 0.5em;
  background: #edeef3;
  box-shadow: none;
}

input[type="range"].slider.slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(#313b49, #313b49) 0 / var(--sx) 100% no-repeat,
    #edeef3;
}

/*mozilla*/
input[type="range"].slider::-moz-range-thumb {
  width: max(calc(16px - 2px - 2px), 0px);
  height: max(calc(16px - 2px - 2px), 0px);
  border-radius: 1em;
  background: #fbfcfd;
  border: 2px solid #313b49;
  box-shadow: 0 0 2px black;
}

input[type="range"].slider::-moz-range-track {
  height: 8px;
  border: none;
  border-radius: 0.5em;
  background: #edeef3;
  box-shadow: none;
}

input[type="range"].slider.slider-progress::-moz-range-track {
  background: linear-gradient(#313b49, #313b49) 0 / var(--sx) 100% no-repeat,
    #edeef3;
}

/*ms*/
input[type="range"].slider::-ms-fill-upper {
  background: transparent;
  border-color: transparent;
}

input[type="range"].slider::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

input[type="range"].slider::-ms-thumb {
  width: 16px;
  height: 16px;
  border-radius: 1em;
  background: #fbfcfd;
  border: 2px solid #313b49;
  box-shadow: 0 0 2px black;
  margin-top: 0;
  box-sizing: border-box;
}

input[type="range"].slider::-ms-track {
  height: 8px;
  border-radius: 0.5em;
  background: #edeef3;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
}

input[type="range"].slider.slider-progress::-ms-fill-lower {
  height: 8px;
  border-radius: 0.5em 0 0 0.5em;
  margin: -undefined 0 -undefined -undefined;
  background: #313b49;
  border: none;
  border-right-width: 0;
}

img.round {
  border-radius: 10px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  vertical-align: top;
  border: 1.5px solid #e4e4e4;
  border-radius: 4px;
  background: #fff no-repeat center center;
  margin-bottom: 0;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background: #313B49 url("../public/images/Icon/icon_check_small.png") no-repeat 50% 50%;
  background-size: 8px;
}

.modalCheckBox {
  width: 16px !important;
  height: 16px !important;
  border-radius: 4px !important;
}

/* select {
  padding: 0.8em 0.5em;
  border: 1px solid #999;
  font-family: inherit;
  background: url("../public/images/Icon/icon_select_arrow.png") no-repeat 95%
    50%;
  border-radius: 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
} */

option {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

input[type="radio"] {
  accent-color: #313b49;
}

input[type="radio"]:focus {
  outline: none;
}

input:focus {
  outline: 1px solid #313b49;
}

input::placeholder {
  color: #afb9ca;
}